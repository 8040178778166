.tajawal-extralight {
    font-family: "Tajawal", sans-serif;
    font-weight: 200;
    font-style: normal;
  }
  
  .tajawal-light {
    font-family: "Tajawal", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .tajawal-regular {
    font-family: "Tajawal", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .tajawal-medium {
    font-family: "Tajawal", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .tajawal-bold {
    font-family: "Tajawal", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .tajawal-extrabold {
    font-family: "Tajawal", sans-serif;
    font-weight: 800;
    font-style: normal;
  }
  
  .tajawal-black {
    font-family: "Tajawal", sans-serif;
    font-weight: 900;
    font-style: normal;
  }
  